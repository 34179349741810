<template>
  <admin-data-card :title="'Utilisateurs inscrits'" :dataNumber="sumUsers">
    <bar-graph
      :hint="'Utilisateurs inscrits'"
      :headers="inscriptionDates"
      :values="inscriptionCounts"
    ></bar-graph>
  </admin-data-card>
</template>

<script>
import UserService from "../../../services/user.service";

export default {
  name: "InscriptionCard",

  components: {
    "admin-data-card": () => import("../../../core/card/AdminDataCard"),
    "bar-graph": () => import("../../../core/graphs/BarGraph"),
  },

  data() {
    return {
      sumUsers: 0,
      inscriptionDates: [],
      inscriptionCounts: [],
    };
  },

  mounted() {
    // Récupration des aggrégats utilisateur
    UserService.getUsersInscriptionCountByDate().then(
      (response) => {
        let users = response.data;

        this.inscriptionDates = users.map((a) => a._id);
        this.inscriptionCounts = users.map((a) => a.count);
      },
      (error) => {
        console.error(error.response);
      }
    );

    // Récupréation du total
    UserService.getUsersCount().then(
      (response) => {
        this.sumUsers = response.data.usersNumber;
      },
      (error) => {
        console.error(error.response);
      }
    );
  },
};
</script>
